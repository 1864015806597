import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import wrapPageElements from './wrap-page-element';
import WrapWithProviders from './wrap-with-providers';
// import { CacheProvider } from '@emotion/react'


// import { myCache } from './emotion-cache'

import type { PersonIdentifiers } from '@seeka-labs/converge';
// import React from 'react';

const getNewRelicBrowserApi = (): typeof newrelic | null => {
    if ((window as any).newrelic) return (window as any).newrelic;

    return null
}

export const onInitialClientRender = () => {
    const isSsr = typeof window === 'undefined';

    if (!isSsr) {
        // Klaviyo
        const klav = (window as any).klaviyo;
        klav?.cookieDomain('blootropic.com').then((r) => { })
            .catch((e) => {
                console.error(e);
            });

        // New relic user ID and release info
        const nr = getNewRelicBrowserApi();
        if (nr) {
            console.debug('New Relic Browser API found');
            if (process.env.GATSBY_NETLIFY_BUILD_ID && process.env.GATSBY_NETLIFY_CONTEXT) {
                nr.addRelease(process.env.GATSBY_NETLIFY_CONTEXT, process.env.GATSBY_NETLIFY_BUILD_ID)
                console.debug('New Relic release added');
            }
            window.addEventListener("converge.identity.changed", (ev) => {
                const seekaIdentity = (ev as any).detail?.identifiers as PersonIdentifiers | undefined | null;
                const seekaPId = seekaIdentity?.seekaPId;

                if (seekaPId)
                    nr.setUserId(seekaPId)
            })
        }
    }
}

export const wrapPageElement = wrapPageElements

export const wrapRootElement = WrapWithProviders

// export const wrapRootElement = (props) => (
//     <CacheProvider value={myCache}>
//         <WrapWithProviders {...props} />
//     </CacheProvider>
// )