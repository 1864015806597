import React from "react"
import Layout from "./src/components/layout"
import GlobalStyles from "@mui/material/GlobalStyles";
import { Script, WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby";

const globalStyles = <GlobalStyles
  styles={(theme) => ({
    '.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg': {
      height: '60px!important',
      width: '20px!important',
      stroke: 'black'
    },
    '.image-gallery-icon': {
      filter: 'none!important',
    },
    '.image-gallery-thumbnail, .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:focus-visible, .image-gallery-thumbnail:hover': {
      border: 'none!important',
      transition: 'none!important'
    },
    '.image-gallery-thumbnail-image:focus-visible': {
      outline: 'none!important',
      border: 'none!important',
      transition: 'none!important'
    },
    '.html p': {
      margin: '0',
      marginBottom: '10px'
    },
    'html': {
      overflowX: 'hidden'
    },
    'body': {
      overflowX: 'hidden'
    },
    '.snackbar-container-root .notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main
    },
    '.MuiAccordionDetails-root p': {
      whiteSpace: 'pre-wrap'
    },
    '.MuiAccordionSummary-content': {
      fontWeight: 'bold'
    }
  })}
/>

export default ({ element, props }: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) => {
  const isSiteFramed = false;

  return (
    <>
      {isSiteFramed ? <></> : <>
        <Script>
          {`
              !function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();
              `}
        </Script>
        {process.env.GATSBY_SHOPIFY_INBOX_CHAT_SCRIPTURL && <Script src={process.env.GATSBY_SHOPIFY_INBOX_CHAT_SCRIPTURL} strategy='idle' />}
        <Script async src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SjPHyt" strategy='idle' />
      </>}

      {globalStyles}
      <Layout location={props.location} isSiteFramed={isSiteFramed}>
        {element}
      </Layout>
    </>
  )
}
