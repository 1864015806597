import { Link, PageProps } from 'gatsby';
import { sum } from 'lodash-es';
import React, { useEffect } from 'react';

import {
  Check, NavigateNext, SentimentDissatisfied, SentimentSatisfiedAlt
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert, Box, Button, Chip, Container, Stack, Table, TableBody, TableCell, TableContainer,
  TableFooter, TableHead, TableRow, Theme, Typography, useTheme
} from '@mui/material';
import {
  AnalyticsPageType, CartLineProvider, CartWithActions, useCart
} from '@shopify/hydrogen-react';
import {
  CartAutomaticDiscountAllocation, CartCodeDiscountAllocation, CartCustomDiscountAllocation,
  MoneyV2
} from '@shopify/hydrogen-react/storefront-api-types';

import { CartLineItem, MoneyDisplay } from '../components/cart';
import SeoHead from '../components/head';
import { HorizontalSlantContainer } from '../components/horizontal-slant-container';
import { useAnalytics } from '../shared/analytics';
import {
  AutomaticDiscount, AutomaticDiscountMinimumRequirementType, AutomaticDiscountType,
  useAutomaticDiscountList
} from '../shared/hooks/useAutomaticDiscountList';
import { headerSlantContainerNoContent } from '../shared/styles/headerSlantContainer';
import { useSeekaConverge } from '@seeka-labs/converge-react';

export const useCartStyling = (theme: Theme) => ({
  productImageCell: {
    display: {
      xs: "none",
      sm: "none",
      md: "none",
      lg: 'table-cell',
      xl: 'table-cell',
    },
  },
  lineItemSubtotalCell: {
    display: {
      xs: "none",
      sm: "none",
      md: 'table-cell',
      lg: 'table-cell',
      xl: 'table-cell',
    },
  },
  cartDesktopActionsCell: {
    display: {
      xs: "none",
      sm: "none",
      md: 'table-cell',
      lg: 'table-cell',
      xl: 'table-cell',
    },
  },
  cartMobileActions: {
    display: {
      xs: "block",
      sm: "block",
      md: 'none',
      lg: 'none',
      xl: 'none',
    },
  },
  cartItemTable: {
    "& td": {
      fontSize: theme.typography.body1.fontSize,
      borderBottom: 'none',
      paddingBottom: theme.spacing(2),
    },
    "& th": {
      fontSize: theme.typography.body1.fontSize,
      borderBottom: 'none',
      paddingBottom: theme.spacing(2),
    }
  },
  tableFooter: {
    "& td": {
      paddingBottom: theme.spacing(0.2),
      fontSize: theme.typography.body1.fontSize,
      borderBottom: 'none',
    },
    "& th": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0.2),
      fontSize: theme.typography.body1.fontSize,
      borderBottom: 'none',
    }
  }
})

export type ExtendedCart = CartWithActions & {
  discountAllocations: Array<
    | CartAutomaticDiscountAllocation
    | CartCodeDiscountAllocation
    | CartCustomDiscountAllocation
  >
}

const NotYetAppliedDiscountAlerts = () => {

  const { notAppliedDiscounts } = useAutomaticDiscountList()
  const { lines, linesUpdate, cost } = useCart() as ExtendedCart;
  const seeka = useSeekaConverge();

  const applyDiscount = (discount: AutomaticDiscount) => {
    if (discount.type === AutomaticDiscountType.Basic) {
      if (discount.minimumRequirement.type === AutomaticDiscountMinimumRequirementType.Quantity && discount.minimumRequirement.greaterThanOrEqualToQuantity) {
        linesUpdate([
          {
            id: lines[0].id,
            merchandiseId: lines[0].merchandise.id,
            quantity: Number(discount.minimumRequirement.greaterThanOrEqualToQuantity)
          }
        ])
      }
    }

    if (discount.type === AutomaticDiscountType.FreeShipping) {
      if (discount.minimumRequirement.type === AutomaticDiscountMinimumRequirementType.Subtotal && discount.minimumRequirement.greaterThanOrEqualToSubtotal) {
        const minSubtotal = Number(discount.minimumRequirement.greaterThanOrEqualToSubtotal?.amount || 0)
        if (minSubtotal > 0) {
          const currentCartSubtotal = (Number(cost.subtotalAmount.amount) || 0);
          const amountRequired = minSubtotal - currentCartSubtotal;
          if (amountRequired > 0) {
            let newQuantity: number | undefined = (lines[0].quantity || 1) + 1;
            while (true) {
              const newCartSubtotal = currentCartSubtotal + (Number(lines[0].cost.amountPerQuantity.amount) * newQuantity);
              if (newCartSubtotal > amountRequired) {
                break;
              }
              newQuantity++
              if (newQuantity > 100) {
                newQuantity = undefined;
                break;
              }
            }
            if (newQuantity) {
              linesUpdate([
                {
                  id: lines[0].id,
                  merchandiseId: lines[0].merchandise.id,
                  quantity: newQuantity
                }
              ])
            }
          }
        }
      }
    }

    seeka.track.custom("EngagedWithCartDiscountAlert", { contentName: discount.type });
  }

  return (
    <Stack direction={{ xs: 'row-reverse', sm: 'row-reverse', md: 'column-reverse' }} spacing={1}>
      {
        notAppliedDiscounts.map((discount) =>
          <Alert key={discount.title} variant='outlined' icon={<SentimentDissatisfied />} severity="warning" action={<Button color='warning' variant='contained' onClick={() => applyDiscount(discount)}>Apply offer</Button>}>
            <Typography variant='body1' component='div'>
              You are missing out on  <Typography variant='body1' component={'span'} sx={{ fontWeight: 'bold' }}>
                {discount.title[0].toLowerCase() + discount.title.slice(1)}
              </Typography>
            </Typography>
          </Alert>
        )
      }
    </Stack>
  )
}


export default function CartPage() {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.trackPageView(AnalyticsPageType.cart);
  }, [])
  const { status, lines, checkoutUrl, cost, discountAllocations } = useCart() as ExtendedCart;

  const { appliedDiscounts, hasFreeShipping } = useAutomaticDiscountList()
  const headerSlantContainerNoContentStyles = headerSlantContainerNoContent();
  const seeka = useSeekaConverge();

  const loading = status !== 'idle';

  const emptyCart = lines.length === 0
  const theme = useTheme();
  const styles = useCartStyling(theme)

  const subtotal = cost?.subtotalAmount as MoneyV2
  const total = cost?.totalAmount as MoneyV2
  const discountsFromCompareAtPrices = sum(lines.filter(e => e.cost.compareAtAmountPerQuantity).map(e => (parseFloat(e.cost.compareAtAmountPerQuantity.amount) - parseFloat(e.cost.amountPerQuantity.amount)) * (e.quantity || 1)))
  const discountsFromDiscountAllocations = sum((discountAllocations || []).map(e => parseFloat(e.discountedAmount.amount)))
  const hasMultipleDiscounts = Boolean(discountsFromCompareAtPrices) && Boolean(discountsFromDiscountAllocations)

  const handleCheckout = () => {
    let url = checkoutUrl
    if (seeka && seeka.attribution && seeka.attribution.addCrossDomainTracking) {
      url = seeka.attribution.addCrossDomainTracking(checkoutUrl)
    }
    else {
      console.warn('Seeka Converge not available')
    }

    window.open(url)
  }

  return (<>
    <HorizontalSlantContainer id={"cart"} autoHeight>
      <Container sx={headerSlantContainerNoContentStyles}></Container>
    </HorizontalSlantContainer>
    <Container sx={{ pt: 6 }}>
      <Stack rowGap={2}>
        <Box>
          <Typography variant="h1" gutterBottom>
            Your cart
          </Typography>
        </Box>

        <Box>
          {emptyCart ? (
            <div>
              <Typography variant="h4" gutterBottom>Cart is empty</Typography>
              <Typography>Looks like you haven't found anything yet</Typography>
              <Button variant='contained' color='primary' sx={{ mt: 2 }} component={Link} to="/products">
                View products
              </Button>
            </div>
          ) : (
            <>
              <Stack direction="column" rowGap={3}>
                {!emptyCart && <NotYetAppliedDiscountAlerts />}

                <Table sx={styles.cartItemTable} aria-label="cart item list" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={styles.productImageCell}></TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell sx={styles.lineItemSubtotalCell} align="right">Unit price</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Total</TableCell>
                      <TableCell sx={styles.cartDesktopActionsCell}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lines.map((item) => (
                      <CartLineProvider line={item} key={item.id}>
                        <CartLineItem isCartLoading={loading} />
                      </CartLineProvider>
                    ))}
                  </TableBody>
                </Table>
              </Stack>

              <Box mt={4}>
                <Stack direction="row" justifyContent="end">
                  <Table aria-label="cart totals list" size="small" sx={{ width: '420px' }}>
                    <TableFooter sx={styles.tableFooter}>
                      {
                        appliedDiscounts && appliedDiscounts.length > 0 && (
                          <TableRow>
                            <TableCell sx={styles.productImageCell}></TableCell>
                            <TableCell></TableCell>
                            <TableCell sx={styles.lineItemSubtotalCell}></TableCell>
                            <TableCell align="right" colSpan={4}>
                              <Stack direction="column"
                                spacing={1}>
                                {
                                  appliedDiscounts.map((discount, index) => {
                                    return <Chip icon={<Check />} variant="filled" color="success" key={index} label={discount.title} />
                                  })
                                }
                              </Stack>
                              <Box pb={2}></Box>
                            </TableCell>
                          </TableRow>
                        )
                      }

                      <TableRow>
                        <TableCell sx={styles.productImageCell}></TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={styles.lineItemSubtotalCell}></TableCell>
                        <TableCell align="right">Subtotal</TableCell>
                        <TableCell>
                          <MoneyDisplay money={subtotal} />
                          {/* {formatPrice(
                        checkout.subtotalPriceV2.currencyCode,
                        checkout.subtotalPriceV2.amount
                      )} */}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {/* <TableRow>
                    <TableCell sx={styles.productImageCell}></TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={styles.lineItemSubtotalCell}></TableCell>
                    <TableCell align="right">Taxes</TableCell>
                    <TableCell>
                      {formatPrice(
                        checkout.totalTaxV2.currencyCode,
                        checkout.totalTaxV2.amount
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow> */}
                      <TableRow>
                        <TableCell sx={styles.productImageCell}></TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={styles.lineItemSubtotalCell}></TableCell>
                        <TableCell sx={[hasFreeShipping ? {
                          color: theme.palette.success.dark
                        } : {
                          color: null
                        }]} align="right">Shipping</TableCell>
                        <TableCell sx={[hasFreeShipping ? {
                          color: theme.palette.success.dark
                        } : {
                          color: null
                        }]}>
                          {hasFreeShipping ? 'FREE' : 'Calculated at checkout'}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {
                        discountsFromCompareAtPrices && discountsFromCompareAtPrices > 0 && (
                          <TableRow>
                            <TableCell sx={styles.productImageCell}></TableCell>
                            <TableCell></TableCell>
                            <TableCell sx={styles.lineItemSubtotalCell}></TableCell>
                            <TableCell align="right" sx={[{
                              color: theme.palette.success.dark
                            }, hasMultipleDiscounts ? {
                              fontWeight: null
                            } : {
                              fontWeight: 'bold'
                            }]}>On sale</TableCell>
                            <TableCell sx={[{
                              color: theme.palette.success.dark
                            }, hasMultipleDiscounts ? {
                              fontWeight: null
                            } : {
                              fontWeight: 'bold'
                            }]}>
                              <Stack direction="row" spacing={1} alignItems='center' justifyContent='flex-start'>
                                <MoneyDisplay money={{ currencyCode: total.currencyCode, amount: discountsFromCompareAtPrices.toFixed(2) }} />
                                {/* <div>
                              {formatPrice(
                                checkout.subtotalPriceV2.currencyCode,
                                checkout.lineItemsSubtotalPrice.amount - checkout.subtotalPriceV2.amount
                              )}
                            </div> */}
                                {!hasMultipleDiscounts && <SentimentSatisfiedAlt />}
                              </Stack>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )
                      }

                      {
                        discountAllocations && discountAllocations.length > 0 && (
                          <TableRow>
                            <TableCell sx={styles.productImageCell}></TableCell>
                            <TableCell></TableCell>
                            <TableCell sx={styles.lineItemSubtotalCell}></TableCell>
                            <TableCell align="right" sx={[{
                              color: theme.palette.success.dark
                            }, hasMultipleDiscounts ? {
                              fontWeight: null
                            } : {
                              fontWeight: 'bold'
                            }]}>Offers</TableCell>
                            <TableCell sx={[{
                              color: theme.palette.success.dark
                            }, hasMultipleDiscounts ? {
                              fontWeight: null
                            } : {
                              fontWeight: 'bold'
                            }]}>
                              <Stack direction="row" spacing={1} alignItems='center' justifyContent='flex-start'>
                                <MoneyDisplay money={{ currencyCode: total.currencyCode, amount: discountsFromDiscountAllocations.toFixed(2) }} />
                                {/* <div>
                              {formatPrice(
                                checkout.subtotalPriceV2.currencyCode,
                                checkout.lineItemsSubtotalPrice.amount - checkout.subtotalPriceV2.amount
                              )}
                            </div> */}
                                {!hasMultipleDiscounts && <SentimentSatisfiedAlt />}
                              </Stack>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )
                      }

                      {
                        hasMultipleDiscounts && (
                          <TableRow>
                            <TableCell sx={styles.productImageCell}></TableCell>
                            <TableCell></TableCell>
                            <TableCell sx={styles.lineItemSubtotalCell}></TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold', color: theme.palette.success.dark }}>Total savings</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: theme.palette.success.dark }}>
                              <Stack direction="row" spacing={1} alignItems='center' justifyContent='flex-start'>
                                <MoneyDisplay money={{ currencyCode: total.currencyCode, amount: (discountsFromCompareAtPrices + discountsFromDiscountAllocations).toFixed(2) }} />
                                {/* <div>
                              {formatPrice(
                                checkout.subtotalPriceV2.currencyCode,
                                checkout.lineItemsSubtotalPrice.amount - checkout.subtotalPriceV2.amount
                              )}
                            </div> */}
                                <SentimentSatisfiedAlt />
                              </Stack>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )
                      }

                      <TableRow>
                        <TableCell sx={styles.productImageCell}></TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={styles.lineItemSubtotalCell}></TableCell>
                        <TableCell align="right" component="th"><strong>Total price</strong></TableCell>
                        <TableCell component="th">
                          <strong> <MoneyDisplay money={total} /></strong>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Stack>
              </Box>

              <Box mt={4}>
                <Stack direction="row" justifyContent="end">
                  <LoadingButton loading={loading} variant='contained' endIcon={<NavigateNext />}
                    onClick={handleCheckout}
                    disabled={loading}>Checkout</LoadingButton>
                </Stack>
              </Box>
            </>
          )}
        </Box>
      </Stack>
    </Container>
  </>);
}

export const Head = (props: PageProps) => {
  return (
    <SeoHead
      title={`Cart`
      }
      pathname={props.location.pathname}
      description={`Blootropic cart`}
    />
  )
}