module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blootropic","short_name":"Blootropic","start_url":"/","background_color":"#F3F8FF","theme_color":"#28a7c7","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"178f2aa638b8f61c8d65252c9c6c8b09"},
    },{
      plugin: require('../node_modules/@seeka-labs/gatsby-plugin-seeka/gatsby-browser.js'),
      options: {"plugins":[],"publicKey":"sdkr_Q2ZESjhCZmx4MVowRWl0RHF2TGp6WmkxcVZkSWdfWHJXcWxNcHBZVXhPeVQtMUx2RDBPc0FpYzJVVjdrZzJrZWRXclFxX3Q5ZEJNeExlbXQ4LXNqWTV6b3dtRXdVT084U214X3FRQjQ3bDJtRUhIMnlXODlLVUR0MnNGb01nUi1sNC1lclVDWXk2TVR1Y3U2b1NieFl4Sm1sSjhKNjBEbnNJSXZSQTZDeFRYMlpUUkI","org":"4e85f8d7a3a314b75a4a3a1017df6036","id":"f2c17d6625796a3a4b183a1017df6f40","hosts":[{"hostname":"7f9e.blootropic.com"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
