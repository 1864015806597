import isAbsoluteUrl from 'is-absolute-url';
import React from 'react';

import roboto400 from '@fontsource/roboto/files/roboto-latin-400-normal.woff2';
import roboto500 from '@fontsource/roboto/files/roboto-latin-500-normal.woff2';
import roboto700 from '@fontsource/roboto/files/roboto-latin-700-normal.woff2';

import defaultImage from '../assets/img/blootropic-logo-colored-seo.png';
import thumbnailImage from '../assets/img/blootropic-logo-colored-seo-thumbnail.png';
import { useSeoMetadata } from '../shared/hooks/useSeoMetadata';

type HeadComponentProps = {
  title?: string
  description?: string
  pathname: string
  image?: { url?: string; publicURL?: string }
  children?: React.ReactNode
}

// export type PrefetchElements = {
//   images?: ContentfulAsset[]
// }

export default function SeoHead({
  title,
  description,
  pathname,
  image,
  children,
}: HeadComponentProps) {
  const seoMetadata = useSeoMetadata();

  const seo = {
    title: title ? `${seoMetadata.site.title} - ${title}` : seoMetadata.site.title,
    description: description || seoMetadata.site.description,
    image: image?.url || image?.publicURL || (defaultImage as string),
    url: `${seoMetadata.site.siteUrl}${pathname || ``}`
  }
  if (!isAbsoluteUrl(seo.image)) {
    // remove leading slash
    seo.image = seo.image.replace(/^\//, '')
    seo.image = `${seoMetadata.site.siteUrl}/${seo.image}`
  }

  return (
    <>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="google-adsense-account" content="ca-pub-7404596419417719" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:site_name" content={seoMetadata.site.title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />

      <meta name="thumbnail" content={thumbnailImage} />
      <link rel="canonical" href={seo.url} />
      <link rel="icon" href={thumbnailImage} type="image/x-icon" />
      <meta name="viewport" content="initial-scale=1, width=device-width" />

      <link
        key="precon-nrjs"
        rel="preconnect"
        crossOrigin='anonymous'
        href="https://js-agent.newrelic.com"
      />
      <link
        key="dns-nrjs"
        rel="dns-prefetch"
        href="https://js-agent.newrelic.com"
      />
      <link
        key="precon-ctfassets"
        rel="preconnect"
        crossOrigin='anonymous'
        href="https://images.ctfassets.net"
      />
      <link
        key="dns-ctfassets"
        rel="dns-prefetch"
        href="https://images.ctfassets.net"
      />

      <script type="application/ld+json" key="schema-org">
        {`
          {
              "@context": "https://schema.org",
              "@type": "Organization",
              "image": "${(defaultImage as string)}",
              "name": "${seoMetadata.site.title}",
              "url": "${seoMetadata.site.siteUrl}",
              "email": "support@blootropic.com",
              "legalName": "Blootropic.com",
              "description": "${seoMetadata.site.description}"
          }
        `}
      </script>

      {children}
    </>
  )
}
