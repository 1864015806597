import { useEffect, useMemo, useState } from 'react';

import {
    AnalyticsEventName, getClientBrowserParameters, sendShopifyAnalytics, ShopifyAddToCartPayload,
    ShopifyAnalyticsProduct, ShopifyPageViewPayload, ShopifySalesChannel, useCart, useShop
} from '@shopify/hydrogen-react';

import { ShopifyPageViewPayloadMinimal } from '../../models/tracking';
import { useSeekaConverge } from '@seeka-labs/converge-react';
import { isTrackingEnabled } from '../../utils';

export const useShopifyAnalytics = () => {
    const shop = useShop();
    const cart = useCart();
    const converge = useSeekaConverge();
    const isTrackingAllowed = isTrackingEnabled()

    const [pendingAddToCartPayloads, setPendingAddToCartPayloads] = useState<ShopifyAddToCartPayload[]>([]);

    const cartId = cart?.id?.split('?')[0]
    const cartValue = cart?.cost?.totalAmount?.amount ? parseFloat(cart?.cost?.totalAmount?.amount) : undefined;

    // console.log('heree shopify cart', cart)

    // TODO: integrate with consent popup
    const hasUserConsent = true;

    useEffect(() => {
        const cartGid = cart?.id;
        if (cartGid && converge) {
            const cartParts = cartGid.split('?')[0]?.split('/');
            if (cartParts.length > 1) {
                const cartToken = cartParts[cartParts.length - 1];
                converge.identity.mergeProfile({
                    shopify: {
                        cartToken: [cartToken]
                    }
                })
            }

        }

    }, [cart?.id])

    const currencyCode = useMemo(() => {
        return cart?.cost?.totalAmount?.currencyCode;
    }, [shop, cart])

    const trackPageView = async (pageType: string, data?: Omit<ShopifyPageViewPayloadMinimal, 'products'> & { products: ShopifyAnalyticsProduct[] }) => {
        if (!isTrackingAllowed) return; // Helps lighthouse score, sendShopifyAnalytics will fail

        // Send analytics payload to Shopify
        try {
            await sendShopifyAnalytics({
                eventName: AnalyticsEventName.PAGE_VIEW,
                payload: {
                    ...getClientBrowserParameters(),
                    ...data,
                    // acceptedLanguage: `${shop.languageIsoCode}-${shop.countryIsoCode}` as LanguageCode, // TODO
                    acceptedLanguage: 'EN',
                    hasUserConsent: hasUserConsent,
                    currency: currencyCode,
                    shopId: `gid://shopify/Shop/${process.env.GATSBY_SHOPIFY_SHOP_ID}`,
                    pageType: pageType,
                    // storefrontId: shop.storefrontId
                } as ShopifyPageViewPayload,
            });
        }
        catch (e) {
            console.log('Shopify analytics error', e)
        }
    }

    useEffect(() => {
        const indexesToRemove: number[] = [];
        if (pendingAddToCartPayloads.length > 0) {
            pendingAddToCartPayloads.forEach(async (payload, index) => {
                if (!payload.cartId && !cartId) return;
                if (payload.totalValue === undefined && cartValue === undefined) return;

                try {
                    indexesToRemove.push(index);
                    await sendShopifyAnalytics({
                        eventName: AnalyticsEventName.ADD_TO_CART,
                        payload: {
                            ...payload,
                            cartId: payload.cartId || cartId,
                            totalValue: payload.totalValue || cartValue,
                        },
                    });
                }
                catch (e) {
                    console.log('Shopify analytics error', e)
                }
            })
        }

        if (indexesToRemove.length > 0) {
            setPendingAddToCartPayloads(pendingAddToCartPayloads.filter((_, index) => !indexesToRemove.includes(index)));
        }
    }, [cartId, cartValue, pendingAddToCartPayloads])

    const trackAddToCart = async (products: ShopifyAnalyticsProduct[]) => {
        if (!isTrackingAllowed) return; // Helps lighthouse score, sendShopifyAnalytics will fail

        const addToCartPayload = {
            ...getClientBrowserParameters(),
            // ...data,
            // acceptedLanguage: `${shop.languageIsoCode}-${shop.countryIsoCode}` as LanguageCode, // TODO
            acceptedLanguage: 'EN',
            hasUserConsent: hasUserConsent,
            shopId: `gid://shopify/Shop/${process.env.GATSBY_SHOPIFY_SHOP_ID}`,
            shopifySalesChannel: ShopifySalesChannel.headless,
            cartId,
            currency: currencyCode,
            products: products,
            totalValue: cartValue,
            // storefrontId: shop.storefrontId
        } as ShopifyAddToCartPayload;

        // console.log('heree addToCartPayload', addToCartPayload)

        if (!addToCartPayload.cartId) {
            // Cart not ready
            setPendingAddToCartPayloads([...pendingAddToCartPayloads, addToCartPayload]);
            return;
        }

        // Send analytics payload to Shopify
        try {
            await sendShopifyAnalytics({
                eventName: AnalyticsEventName.ADD_TO_CART,
                payload: addToCartPayload,
            });
        }
        catch (e) {
            console.log('Shopify analytics error', e)
        }

    }

    return {
        trackPageView,
        trackAddToCart
    }
}