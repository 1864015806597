export default {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: ({}) => {
      return {
        textTransform: 'inherit' as any,

        // padding: '0.75rem 1.5rem',
        lineHeight: 'normal',

        // fontWeight: 500,
        // fontSize: '0.875rem',
        whiteSpace: 'nowrap' as any,

        ':hover': {},

        variants: [{
          props: (
            {
              ownerState
            }
          ) => ownerState.size !== 'small',

          style: {
            padding: '0.75rem 1.5rem',
            borderRadius: '0.75rem',
          }
        }, {
          props: (
            {
              ownerState
            }
          ) => ownerState.size == 'small',

          style: {
            //padding: '0.75rem 1.5rem',
            borderRadius: '0.5rem',
            padding: '0.3rem 0.7rem',
          }
        }, {
          props: (
            {
              ownerState
            }
          ) => ownerState.variant == 'outlined',

          style: {
            borderWidth: '2px'
          }
        }, {
          props: (
            {
              ownerState
            }
          ) => ownerState.variant == 'outlined',

          style: {
            ':hover': {
              borderWidth: '2px'
            }
          }
        }]
      };
    },
  }
}
