import { graphql, useStaticQuery } from 'gatsby';
import { uniq } from 'lodash-es';

import { useCart } from '@shopify/hydrogen-react';
import { MoneyV2 } from '@shopify/hydrogen-react/storefront-api-types';

import { ExtendedCart } from '../../pages/cart';

export enum AutomaticDiscountType {
  Basic = 'ShopifyAdmin_DiscountAutomaticBasic',
  FreeShipping = 'ShopifyAdmin_DiscountAutomaticFreeShipping'
}

export enum AutomaticDiscountMinimumRequirementType {
  Quantity = 'ShopifyAdmin_DiscountMinimumQuantity',
  Subtotal = 'ShopifyAdmin_DiscountMinimumSubtotal'
}

export interface AutomaticDiscount {
  title: string
  type: AutomaticDiscountType
  minimumRequirement: AutomaticDiscountMinimumRequirement
  status: AutomaticDiscountStatus
}

export interface AutomaticDiscountMinimumRequirement {
  type: AutomaticDiscountMinimumRequirementType
  greaterThanOrEqualToQuantity?: number
  greaterThanOrEqualToSubtotal?: MoneyV2
}

export enum AutomaticDiscountStatus {
  Active = 'ACTIVE',
  Scheduled = 'SCHEDULED',
  Expired = 'EXPIRED'
}

export const useAutomaticDiscountList = () => {
  const discountQuery: Queries.AutomaticDiscountsQuery = useStaticQuery(graphql`
        query AutomaticDiscounts {
            shopifyAdmin {
                automaticDiscountNodes(first: 10, sortKey: CREATED_AT) {
                  nodes {
                    automaticDiscount {
                      type: __typename
                      ... on ShopifyAdmin_DiscountAutomaticFreeShipping {
                        title
                        status
                        freeShippingDiscountClass: discountClass
                        minimumRequirement {
                          type: __typename
                          ... on ShopifyAdmin_DiscountMinimumQuantity {
                            greaterThanOrEqualToQuantity
                          }
                          ... on ShopifyAdmin_DiscountMinimumSubtotal {
                            greaterThanOrEqualToSubtotal {
                              amount
                              currencyCode
                            }
                          }
                        }
                      }
                      ... on ShopifyAdmin_DiscountAutomaticBasic {
                        title
                        status
                        basicDiscountClass: discountClass
                        minimumRequirement {
                          type: __typename
                          ... on ShopifyAdmin_DiscountMinimumQuantity {
                            greaterThanOrEqualToQuantity
                          }
                          ... on ShopifyAdmin_DiscountMinimumSubtotal {
                            greaterThanOrEqualToSubtotal {
                              amount
                              currencyCode
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }           
        }
        `)

  const { cost, discountAllocations } = useCart() as ExtendedCart;

  const allAutomaticDiscounts = ((discountQuery?.shopifyAdmin?.automaticDiscountNodes?.nodes || []).map(e => e.automaticDiscount) as AutomaticDiscount[]).filter(e => e.status === AutomaticDiscountStatus.Active);

  const automaticDiscounts = !cost ? [] : allAutomaticDiscounts.filter(e => {
    if (e.type === AutomaticDiscountType.Basic) return true;

    if (e.type === AutomaticDiscountType.FreeShipping && Number(e.minimumRequirement.greaterThanOrEqualToSubtotal?.amount) > 0) {
      const minSubtotal = Number(e.minimumRequirement.greaterThanOrEqualToSubtotal?.amount || 0)
      const currentCartSubtotal = (Number(cost.subtotalAmount.amount) || 0);
      if (minSubtotal > 0 && currentCartSubtotal <= minSubtotal) {
        return true
      }
    }
    return false;
  })

  const appliedDiscounts = !cost ? [] : (allAutomaticDiscounts || []).map((e) => {
    if (e.type === AutomaticDiscountType.Basic && discountAllocations?.find((d) => (d as any).title as string === e.title)) {
      return e;
    };

    if (e.type === AutomaticDiscountType.FreeShipping && Number(e.minimumRequirement.greaterThanOrEqualToSubtotal?.amount) > 0) {
      const minSubtotal = Number(e.minimumRequirement.greaterThanOrEqualToSubtotal?.amount || 0)
      const currentCartSubtotal = (Number(cost.subtotalAmount.amount) || 0);
      if (minSubtotal > 0 && currentCartSubtotal >= minSubtotal) {
        return e
      }
    }

    return null;
  }).filter(e => Boolean(e))

  const appliedDiscountTitles = uniq(appliedDiscounts.map(e => e.title)) as string[]
  const notYetAppliedDiscounts = (automaticDiscounts || []).filter(e => !appliedDiscountTitles.includes(e.title))

  return {
    appliedDiscounts: appliedDiscounts,
    notAppliedDiscounts: notYetAppliedDiscounts,
    activeDiscounts: allAutomaticDiscounts,
    hasFreeShipping: Boolean(appliedDiscounts.find(e => e.type === AutomaticDiscountType.FreeShipping)),
  }
}